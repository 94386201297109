
.vertical-timeline * {
    box-sizing: border-box;
}
.vertical-timeline {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 2em 0;
}
.vertical-timeline::after {
    content: '';
    display: table;
    clear: both;
}
.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 1px;
    background: #989EA4;
}
.vertical-timeline.vertical-timeline--one-column-right::before {
    right: 18px;
    left: unset;
}
@media only screen and (min-width:1170px) {
.vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
}
}.vertical-timeline-element {
    position: relative;
    margin: 2em 0;
}
.vertical-timeline-element>div {
    min-height: 1px;
}
.vertical-timeline-element:after {
    content: "";
    display: table;
    clear: both;
}
.vertical-timeline-element:first-child {
    margin-top: 0;
}
.vertical-timeline-element:last-child {
    margin-bottom: 0;
}
@media only screen and (min-width:1170px) {
    .vertical-timeline-element {
    margin: 4em 0;
}
.vertical-timeline-element:first-child {
    margin-top: 0;
}
.vertical-timeline-element:last-child {
    margin-bottom: 0;
}
}.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color:#99CBC7 ;
}
.vertical-timeline--one-column-right .vertical-timeline-element-icon {
    right: 0;
    left: unset;
}
.vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}
.row-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.row-wrapper.inverse{
    display: flex;
    flex-direction: row-reverse;
}
.row-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 150px;
    
}
.row-column h5 {
    position: relative;
    font-weight: 500;
    color:#fff;
    margin: 10px 0 0 0 ;
}
.row-column p {
    position: relative;
    font-weight: 300 !important;
    color: #ffffff80;
    margin: 0 !important;
    font-size: 14px !important;
    line-height: 1.1rem !important;
    text-align: center;
}
.logowrapper {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logowrapper  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: relative !important;
}
.logowrapper  svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: relative !important;
}

.logowrapper  h4 {
    color: #07146380;
    font-size: 50px;
}
@media (min-width: 1440px) {
    .row-column {
        width: unset;
    }
}
@media only screen and (min-width:1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 10px;
    height: 10px;
    left: 50%;
    top:50%;
    translate: 0 -50%;
    margin-left: -7px;

}
}.vertical-timeline-element-icon {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
    visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 .6s;
    -moz-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s;
}
@-webkit-keyframes cd-bounce-1 {
    0% {
    opacity: 0;
    -webkit-transform: scale(.5);
}
60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
}
100% {
    -webkit-transform: scale(1);
}
}@-moz-keyframes cd-bounce-1 {
    0% {
    opacity: 0;
    -moz-transform: scale(.5);
}
60% {
    opacity: 1;
    -moz-transform: scale(1.2);
}
100% {
    -moz-transform: scale(1);
}
}@keyframes cd-bounce-1 {
    0% {
    opacity: 0;
    -webkit-transform: scale(.5);
    -moz-transform: scale(.5);
    -ms-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
}
60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
}.vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: #fff;
    border-radius: .25em;
    padding: 1em;
    /* box-shadow: 0 3px 0 #ddd; */
}
@media (max-width: 768px) {
    .vertical-timeline-element-content {
    display: flex;
    flex-direction: column-reverse;
    }
}
@media (max-width: 431px) {
    .row-wrapper{
        display: flex;
        flex-direction: column;
    }
}
.vertical-timeline--one-column-right .vertical-timeline-element-content {
    margin-right: 60px;
    margin-left: unset;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
    background: 0 0;
    box-shadow: none;
}
.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
}
.vertical-timeline-element-content h2 {
    color: #303e49;
}
.vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline-element-content p {
    font-size: 1.25rem;
    font-weight: 700;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
    display: inline-block;

}
.vertical-timeline-element-content p {
    line-height: 1.6;
}
.vertical-timeline-element-title {
    margin: 0;
}
.vertical-timeline-element-subtitle {
    margin: 0;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
    float: left;
    padding: .8em 0;
    opacity: 1;
    
}
.vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #fff;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: unset;
    left: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: 7px solid #fff;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content::before {
    left: 100%;
    border-left: 7px solid #fff;
    border-right: unset;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content::before {
    display: none;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content-arrow {
    display: none;
}
@media only screen and (min-width:768px) {
    .vertical-timeline-element-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 1.5rem;
    color: #337E84;
}
}@media only screen and (min-width:1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1.5em;
    width: 44%}
.vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    transform: rotate(180deg);
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top:50%;
    translate: 0 -50%;
    color: #337E84;
}

.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
    float: right;
}
.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    transform: rotate(0);
}
.vertical-timeline--one-column-right .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow, .vertical-timeline--one-column-right .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    right: auto;
    transform: rotate(0);
}
.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    left: auto;
    right: 124%;
    text-align: right;
    color: #337E84;
    top:50%;
    translate: 0 -50%;
}
}.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
    visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 .6s;
    -moz-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s;
}
@media only screen and (min-width:1170px) {
    .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in, .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse .6s;
    -moz-animation: cd-bounce-2-inverse .6s;
    animation: cd-bounce-2-inverse .6s;
}
}@media only screen and (max-width:1169px) {
    .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse .6s;
    -moz-animation: cd-bounce-2-inverse .6s;
    animation: cd-bounce-2-inverse .6s; 
}
    .vertical-timeline-element-content{
        display: flex;
        flex-direction: column-reverse;
    }
    .vertical-timeline-element-icon {
        left: calc(60px / 4.5);
    }
    .row-wrapper.inverse{
        flex-direction: row;
    }
    .row-column h5 {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        color:#fff;
        margin: 10px 0 0 0 ;
    }
}
@-webkit-keyframes cd-bounce-2 {
    0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
}
60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
}
100% {
    -webkit-transform: translateX(0);
}
}@-moz-keyframes cd-bounce-2 {
    0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
}
60% {
    opacity: 1;
    -moz-transform: translateX(20px);
}
100% {
    -moz-transform: translateX(0);
}
}@keyframes cd-bounce-2 {
    0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
}
60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
}
100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
}@-webkit-keyframes cd-bounce-2-inverse {
    0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
}
60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
}
100% {
    -webkit-transform: translateX(0);
}
}@-moz-keyframes cd-bounce-2-inverse {
    0% {
    opacity: 0;
    -moz-transform: translateX(100px);
}
60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
}
100% {
    -moz-transform: translateX(0);
}
}@keyframes cd-bounce-2-inverse {
    0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
}
60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
}
100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
}